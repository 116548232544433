<template lang="">
    <div>
        <Customer/>
    </div>
</template>
<script>
export default {
    components: {
        Customer : () => import('@/components/manage/customer/Customer.vue')
    }
}
</script>
<style lang="">
    
</style>